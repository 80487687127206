import * as React from 'react';
import { map } from 'lodash-es';
import { Col, Row } from 'reactstrap';
import Page from '../../components/Page/page';
import ProductCard from '../../components/ProductCard/product-card';

const Products = ({ location }) => {

    const products = [{
        icon: '/images/llama-clock-face.png',
        name: 'Sense-ible Llama Clock',
        price: 0,
        actions: [{
            label: 'Get it from Fitbit',
            url: 'https://gallery.fitbit.com/details/3aa8b2f6-bd9a-4ac2-8353-01e8ac5f5eb5'
        }]
    }, {
        icon: '/images/Llama Survival Icon.jpg',
        name: 'Llama Survival',
        price: 0,
        actions: [{
            label: 'Get on Android',
            url: 'https://play.google.com/store/apps/details?id=com.LlamaSoftware.LlamaSurvival&gl=US',
        }, {
            label: 'Get on IOS',
            url: 'https://apps.apple.com/us/app/llama-survival/id1479980212',
            color: 'secondary'
        }, {
            label: 'Learn More',
            url: '/products/llama-survival',
            outline: true,
        }]
    }, {
        icon: '/images/llama-clock-face-old.jpg',
        name: 'Llama Clock Face',
        price: 0,
        actions: [{
            label: 'Get it from Fitbit',
            url: 'https://gallery.fitbit.com/details/c0a195bb-e92e-4c87-8be0-3f941384b317'
        }]
    }, {
        icon: '/images/Essential UI Pack Icon.png',
        name: 'Essential UI Pack',
        price: 10,
        actions: [{
            label: 'View on Asset Store',
            url: 'https://assetstore.unity.com/packages/tools/gui/essential-ui-pack-73670?aid=1101l9QvC',
        }, {
            label: 'Learn More',
            url: '/products/essential-ui-pack',
            outline: true,
        }]
    }, {
        icon: '/images/Llama Chat Icon.jpg',
        name: 'Llama Chat',
        price: 20,
        actions: [{
            label: 'View on Asset Store',
            url: 'https://assetstore.unity.com/packages/tools/network/llama-chat-124562?aid=1101l9QvC'
        }, {
            label: 'Learn More',
            url: '/products/llama-chat',
            outline: true
        }]
    }, {
        icon: '/images/Simple Chat Icon.png',
        name: 'Simple Chat',
        price: 5,
        actions: [{
            label: 'View on Asset Store',
            url: 'https://assetstore.unity.com/packages/tools/network/simple-chat-66558?aid=1101l9QvC'
        }, {
            label: 'Learn More',
            url: '/products/simple-chat',
            outline: true
        }]
    }, {
        icon: '/images/Light LOD Icon.png',
        name: 'Light LOD',
        price: 0,
        actions: [{
            label: 'View on Asset Store',
            url: 'https://assetstore.unity.com/packages/tools/video/lightlod-106846?aid=1101l9QvC'
        }, {
            label: 'Learn More',
            url: '/products/light-lod',
            outline: true
        }]
    }, {
        icon: '/images/hideandseek-180.jpg',
        name: 'Hide and Seek',
        price: 0,
        actions: [{
            label: 'View on Steam',
            url: 'https://store.steampowered.com/app/596940/Hide_and_Seek/'
        }, {
            label: 'Learn More',
            url: '/products/hide-and-seek',
            outline: true
        }
        ],
    }];

    return (
        <Page location={location} title="Products" seoTitle="Products" titleClassName='text-center' animateHeader>
            <Row>
                {
                    map(products, (product, index) => {
                        return (
                            <Col className={`animate__animated animate__fadeInUp animate__faster animate__delay-${index * 250}ms`} key={index} xs="12" md="6" lg="6" xl="3">
                                <ProductCard key={product.name} {...product} />
                            </Col>
                        )
                    })
                }
            </Row>
        </Page>
    );
};

export default Products;