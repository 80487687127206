import * as React from "react"
import { Link } from 'gatsby';
import { map } from 'lodash-es';
import { Button, Card, CardBody, CardImg, CardSubtitle, CardTitle } from 'reactstrap';
import './product-card.css';

const NewsCard = ({ actions, icon, name, price }) => {
    return (
        <Card color="dark" inverse className="mb-1">
            <CardImg src={icon} top />
            <CardBody className="w-100">
                <CardTitle tag="h4" className="text-center">
                    {name}
                </CardTitle>
                <CardSubtitle tag="h5" className="text-center">
                    {price === 0 ? <strong>FREE</strong> : <strong>${price.toFixed(2)}</strong>}
                </CardSubtitle>
                <div className="text-center mt-5">
                    {map(actions, action => (
                        <div key={action.url} className="mb-2">
                            <Link to={action.url}>
                                <Button block color={action.color || "primary"} outline={action.outline}>
                                    {action.label}
                                </Button>
                            </Link>
                        </div>
                    ))}
                </div>
            </CardBody>
        </Card>
    );
};

export default NewsCard;